var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    [
      _c(
        "CCardHeader",
        [
          _c("CIcon", { attrs: { name: "cil-map" } }),
          _vm._v(" Vue Google Maps "),
          _c(
            "a",
            {
              staticClass: "badge badge-danger ml-1",
              attrs: {
                href: "https://coreui.io/pro/vue/",
                rel: "noreferrer noopener",
                target: "_blank"
              }
            },
            [_vm._v(" CoreUI Pro ")]
          ),
          _c("div", { staticClass: "card-header-actions" }, [
            _c(
              "a",
              {
                staticClass: "card-header-action",
                attrs: {
                  href: "https://github.com/xkjyeah/vue-google-maps",
                  rel: "noreferrer noopener",
                  target: "_blank"
                }
              },
              [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
            )
          ])
        ],
        1
      ),
      _c(
        "CCardBody",
        [
          _c(
            "GmapMap",
            {
              staticStyle: { height: "400px" },
              attrs: { center: _vm.center, zoom: 11 }
            },
            [
              _c(
                "GmapInfoWindow",
                {
                  attrs: {
                    options: _vm.infoOptions,
                    position: _vm.infoWindowPos,
                    opened: _vm.infoWinOpen
                  },
                  on: {
                    closeclick: function($event) {
                      _vm.infoWinOpen = false
                    }
                  }
                },
                [
                  _c(
                    "CLink",
                    { attrs: { href: _vm.infoLink, target: "_blank" } },
                    [_vm._v(_vm._s(_vm.infoContent))]
                  )
                ],
                1
              ),
              _vm._l(_vm.markers, function(m, index) {
                return _c("GmapMarker", {
                  key: index,
                  attrs: {
                    position: m.position,
                    label: m.label,
                    title: m.title,
                    clickable: true,
                    draggable: m.draggable
                  },
                  on: {
                    click: function($event) {
                      return _vm.toggleInfoWindow(m, index)
                    }
                  }
                })
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }